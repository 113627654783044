import { gql } from 'graphql-request';

export const fragments = {
  courses: gql`
    fragment courses on CourseType {
      _id
      name
      url
      imageUrl
      coverImageUrl
      author {
        url
        imageUrl
      }
      lessons {
        _id
        duration
      }
      duration
      member_only
    }
  `
};

export const GET_LEARNING_PATH_BY_URL = gql`
  query findOneLearningPathByUrl($url: String!) {
    findOneLearningPathByUrl(url: $url) {
      _id
      name
      intro
      description
      objectives
      imageUrl
      prerequisites
      courses {
        ...courses
      }
    }
  }
  ${fragments.courses}
`;

export const GET_LESSON_BY_URL = gql`
  query findOneLessonByUrl($url: String!) {
    findOneLessonByUrl(url: $url) {
      _id
      name
      intro
      description
      imageUrl
      member_only
      coverImageUrl
      videoUrl
      duration
      mediaId
      url
      transcript
      course {
        ...courses
      }
      courses {
        ...courses
        price
      }
      categories {
        _id
        name
      }
      courseId
      topics {
        _id
        name
      }
      author {
        _id
        firstName
        lastName
        imageUrl
        url
      }
    }
  }
  ${fragments.courses}
`;

export const GET_COURSES_BY_URL = gql`
  query findOneCourseByUrl($url: String!) {
    findOneCourseByUrl(url: $url) {
      _id
      url
      member_only
      averageRating
      ratingsCount
      sumRating
      reviews {
        _id
        description
        rating
        user {
          firstName
          lastName
        }
      }
      comments {
        _id
        comment
        isAuthor
        user {
          firstName
          lastName
        }
      }
      name
      intro
      description
      imageUrl
      price
      coverImageUrl
      duration
      objectives
      originalPublishedDate
      author {
        _id
        firstName
        lastName
        imageUrl
        url
        bio
      }
      lessons {
        _id
        url
        name
        imageUrl
        duration
        member_only
        lastUpdatedDate
        videoUrl
        author {
          _id
        }
      }
      progress {
        isActive
        isCompleted
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_COURSE_BY_ID = gql`
  query findOneCourse($id: String!) {
    findOneCourse(id: $id) {
      _id
      url
      member_only
      name
      intro
      coverImageUrl
      imageUrl
      price
      description
      objectives
      transcript
      level
      categories {
        _id
        name
        topics {
          _id
          name
        }
      }
      topics {
        _id
        name
      }
      author {
        _id
        firstName
        lastName
        imageUrl
        url
      }
      lessons {
        _id
        url
        name
        intro
        description
        objectives
        transcript
        categories {
          _id
          name
        }
        topics {
          _id
          name
        }
        courseId
        imageUrl
        storageUrl
        duration
        mediaId
        coverImageUrl
        videoUrl
        member_only
        lastUpdatedDate
        author {
          _id
          firstName
          lastName
          imageUrl
          url
        }
      }
    }
  }
`;

export const CATEGORIES = function (item) {
  return gql`
  query ${item}($page: Float, $limit: Float) {
    ${item}(input: { page: $page, limit: $limit }) {
      itemsList {
          _id
          url
          name
          description
          imageUrl
      }
    }
  }
`;
};

export const SUBSCRIPTIONS = gql`
  query {
    subscriptions {
      _id
      name
      currency {
        name
        symbol
        _id
      }
      cost
      duration
    }
  }
`;

export const PLANS = gql`
  query {
    plans {
      _id
      name
      description
      amount
      currency {
        _id
        name
        symbol
      }
      interval
    }
  }
`;

export const GET_ALL_CURRENCIES = gql`
  query {
    fetchCurrencies {
      _id
      name
      symbol
    }
  }
`;

export const ALL_LEARNING_PATHS = gql`
  query learningPaths($input: QueryLearningPathInput, $whereCondition: LearningPathWhereInput) {
    learningPaths(input: $input, whereCondition: $whereCondition) {
      paginator {
        itemCount
        currentPage
      }
      itemsList {
        _id
        url
        name
        description
        imageUrl
        courses {
          _id
          name
        }
      }
    }
  }
`;

export const ALL_LEARNING_PATHS_WITH_COURSES = gql`
  query learningPaths($input: QueryLearningPathInput, $whereCondition: LearningPathWhereInput) {
    learningPaths(input: $input, whereCondition: $whereCondition) {
      itemsList {
        _id
        url
        name
        description
        imageUrl
        courses {
          ...courses
        }
      }
    }
  }
  ${fragments.courses}
`;

export const ALL_CATEGORIES = gql`
  query categories($input: QueryCategoryInput!) {
    categories(input: $input) {
      itemsList {
        _id
        url
        name
        description
        imageUrl
        coverImageUrl
        courses {
          _id
        }
        topics {
          _id
          name
        }
      }
      paginator {
        itemCount
        currentPage
      }
    }
  }
`;

export const ALL_CATEGORIES_WITH_PATHS = gql`
  query categories($input: QueryCategoryInput!) {
    categories(input: $input) {
      itemsList {
        _id
        url
        name
        description
        imageUrl
        coverImageUrl
        courses {
          _id
        }
        learningPath {
          name
          intro
          description
          objectives
          imageUrl
          prerequisites
          url
          courses {
            _id
          }
        }
      }
      paginator {
        itemCount
        currentPage
      }
    }
  }
`;

export const ALL_TOPICS = gql`
  query topics($input: QueryTopicInput!) {
    topics(input: $input) {
      itemsList {
        _id
        url
        name
        description
        imageUrl
        coverImageUrl
        courses {
          _id
        }
      }
      paginator {
        itemCount
        currentPage
      }
    }
  }
`;

export const ALL_COURSES = gql`
  query courses($input: QueryCourseInput, $whereCondition: CourseWhereInput) {
    courses(input: $input, whereCondition: $whereCondition) {
      itemsList {
        _id
        url
        name
        imageUrl
        coverImageUrl
        duration
        member_only
        author {
          _id
          url
          firstName
          lastName
          imageUrl
        }
        lessons {
          _id
          duration
        }
        categories {
          _id
          name
        }
        topics {
          _id
          name
        }
      }
      paginator {
        itemCount
        currentPage
      }
    }
  }
`;

export const COURSE_PAID_FOR_BY_USER = gql`
  query coursesPaidForByUser($option: QueryCourseSubscriptionOptions) {
    coursesPaidForByUser(options: $options) {
      itemsList {
        _id
        course {
          _id
          name
          url
          imageUrl
          member_only
          isPublished
          averageRating
          duration
          author {
            _id
            lastName
            firstName
          }
          isBookmarked
          numLessons
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_COURSE_REVIEWS = gql`
  query getCourseReviews($input: QueryReviewByCourseInput!) {
    getCourseReviews(input: $input) {
      _id
      description
      rating
      user {
        _id
        firstName
        lastName
      }
    }
  }
`;

export const AUTHOR_COURSES = gql`
  query authorCourses($input: MetricFilterInput!) {
    authorCourses(input: $input) {
      _id
      name
      description
      numEnrollments
      numCompletedEnrollments
      percentageEnrollmentCompleted
      numSales
      saleRevenue
      totalSegments
      totalMinutesWatched
      subscriptionRevenue
      totalRevenue
    }
  }
`;

export const GET_COURSE_COMMENTS = gql`
  query getCourseReviews($input: QueryReviewByCourseInput!) {
    getCourseReviews(input: $input) {
      _id
      description
      rating
      user {
        _id
        firstName
        lastName
      }
    }
  }
`;

export const ALL_LESSONS = gql`
  query lessons($input: QueryLessonInput, $whereCondition: LessonWhereInput) {
    lessons(input: $input, whereCondition: $whereCondition) {
      itemsList {
        _id
        url
        name
        imageUrl
        duration
        member_only
        author {
          _id
          url
          firstName
          lastName
          imageUrl
        }
        courses {
          _id
        }
      }
      paginator {
        itemCount
        currentPage
      }
    }
  }
`;

export const REQUEST_LESSON_DOWNLOAD = gql`
  query requestLessonDownloadToken($input: String!) {
    requestLessonDownloadToken(lessonId: $input) {
      token
    }
  }
`;

export const GET_BANKS = gql`
  query {
    banks(input: { country: "nigeria" }) {
      name
      code
    }
  }
`;

export const GET_CURRENCIES = gql`
  query {
    fetchCurrencies {
      _id
      name
      symbol
    }
  }
`;

export const GET_USER_WALLET = gql`
  query {
    userWallet {
      _id
      balance
    }
  }
`;

export const GET_USER_BENEFICIARIES = gql`
  query {
    userBankDetails {
      _id
      accountName
      accountNumber
      bankCode
      recipientCode
      isPrimary
      isDeleted
      userId
      currency {
        name
      }
    }
  }
`;

export const GET_USER_LAST_5_WITHDRAWALS = gql`
  query {
    userWithdrawals(input: { page: 0, limit: 5 }) {
      itemsList {
        _id
        reference
        amount
        status
        failureReason
        createdAt
        userId
        bankDetail {
          accountName
          accountNumber
          bankCode
        }
      }
    }
  }
`;

export const GET_USER_WITHDRAWALS = gql`
  query downloadableUserWithdrawalReport($input: WithdrawalQueryInput!) {
    downloadableUserWithdrawalReport(input: $input) {
      reference
      amount
      status
      failureReason
      createdAt
      userId
      bankDetail {
        accountName
        accountNumber
        bankCode
      }
    }
  }
`;

export const GET_CATEGORY_BY_URL = gql`
  query findOneCategoryByUrl($url: String!) {
    findOneCategoryByUrl(url: $url) {
      _id
      name
      url
      description
      imageUrl
      coverImageUrl
    }
  }
`;

export const GET_TOPIC_BY_URL = gql`
  query findOneTopicByUrl($url: String!) {
    findOneTopicByUrl(url: $url) {
      _id
      name
      url
      description
    }
  }
`;

export const ALL_USERS = gql`
  query users($input: QueryValue, $whereCondition: UserWhereInput) {
    users(input: $input, whereCondition: $whereCondition) {
      itemsList {
        _id
        firstName
        lastName
        role
        imageUrl
        url
      }
      paginator {
        itemCount
        currentPage
      }
    }
  }
`;

export const GET_USER_BY_URL = gql`
  query findUserByUrl($url: String!) {
    findUserByUrl(url: $url) {
      _id
      url
      firstName
      lastName
      imageUrl
      bio
      twitterUrl
      linkedinUrl
      instagramUrl
      facebookUrl
      website
      lessons {
        _id
        url
        name
        imageUrl
        duration
        member_only
        lastUpdatedDate
        author {
          url
          imageUrl
          firstName
          lastName
        }
      }
      courses {
        _id
        name
        url
        imageUrl
        coverImageUrl
        author {
          url
          imageUrl
          firstName
          lastName
        }
        lessons {
          _id
          duration
        }
        duration
      }
    }
  }
`;
export const GET_CATEGORY_BY_URL_WITH_PATHS = gql`
  query findOneCategoryByUrl($url: String!) {
    findOneCategoryByUrl(url: $url) {
      _id
      name
      url
      description
      imageUrl
      coverImageUrl
      learningPath {
        _id
        url
        name
        description
        imageUrl
      }
    }
  }
`;

export const GET_CURRENCY_EXCHANGE_RATE = gql`
  query FetchAllRates {
    FetchAllRates {
      _id
      currency {
        _id
        name
        symbol
      }
      conversion_rate
    }
  }
`;

export const GET_TEAM_USERS = gql`
  query getTeamDetails {
    getTeamDetails {
      numAllocatedMemberSlots
      members {
        user {
          _id
          firstName
          lastName
          email
          imageUrl
          url
        }
      }
    }
  }
`;

export const GET_PENDING_TEAM_INVITATIONS = gql`
  query getPendingTeamInvitations {
    getPendingTeamInvitations {
      _id
      email
      acceptedAt
      declinedAt
      canceledAt
      expiryDate
      invitee {
        firstName
        lastName
      }
    }
  }
`;

export const ALL_TESTIMONIALS = gql`
  query testimonials($input: QueryTestimonialValue!) {
    testimonials(input: $input) {
      itemsList {
        _id
        description
        name
        title
        imageUrl
      }
    }
  }
`;

export const ALL_PARTNERS = gql`
  query partners($input: QueryPartnerValue!) {
    partners(input: $input) {
      itemsList {
        _id
        name
        logo
        website
      }
    }
  }
`;

export const USER_WATCH_HISTORY = gql`
  query userWatchHistory {
    userWatchHistory {
      _id
      lessonId
      lesson {
        _id
        name
        intro
        description
        imageUrl
        url
        author {
          _id
          firstName
          lastName
        }
      }
    }
  }
`;

export const USER_ENROLLED_COURSES = gql`
  query enrolledCourses($input: QueryEnrolledCourseInput!) {
    enrolledCourses(input: $input) {
      _id
      courseId
      course {
        name
      }
      isCompleted
      durationTrack {
        totalDuration
        totalTimeWatched
      }
      isCompleted
      course {
        _id
        name
        description
        imageUrl
        url
        lessons {
          _id
          name
          videoUrl
          duration
          progress {
            currentPosition
            isCompleted
          }
        }
      }
    }
  }
`;

export const USER_BOOKMARKED_RESOURCES = gql`
  query bookmarkedResources($input: QueryBookmarkedResourceInput!) {
    bookmarkedResources(input: $input) {
      paginator {
        itemCount
      }
      itemsList {
        _id
        resourceId
        resourceType
        lesson {
          _id
          name
          description
          imageUrl
          url
          member_only
          author {
            _id
            firstName
            lastName
          }
        }
        course {
          _id
          name
          description
          imageUrl
          url
          member_only
          author {
            _id
            firstName
            lastName
          }
        }
        learningPath {
          _id
          name
          description
          imageUrl
          url
          member_only
          author {
            _id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const AUTHOR_COURSE_SALES = gql`
  query authorCourseSales($input: MetricFilterInput) {
    authorCourseSales(input: $input) {
      _id
      name
      numSales
      revenue
    }
  }
`;

export const AUTHOR_SUBSCRIPTION_EARNINGS = gql`
  query authorSubscriptionEarnings($input: MetricFilterInput) {
    authorSubscriptionEarnings(input: $input) {
      _id
      name
      numSales
      revenue
    }
  }
`;

export const PASSWORD_RESET_STATUS = gql`
  query passwordResetStatus($id: String!) {
    passwordResetStatus(id: $id) {
      _id
      token
    }
  }
`;

export const GET_COURSE_REVIEWS_BY_USER = gql`
  query getCourseReviewRatingbyUser($courseId: String!) {
    getCourseReviewRatingbyUser(courseId: $courseId) {
      _id
      description
      rating
      user {
        _id
        firstName
        lastName
      }
    }
  }
`;

export const GET_REVIEW_COMMENTS_BY_COURSE = gql`
  query findReviewCommentsByCourse($courseId: String!) {
    findReviewCommentsByCourse(courseId: $courseId) {
      _id
      comment
      isAuthor
      user {
        _id
        firstName
        lastName
        imageUrl
      }
      replies {
        _id
        comment {
          _id
        }
        reply
        isAuthor
        user {
          _id
          firstName
          lastName
          imageUrl
        }
      }
    }
  }
`;

export const GET_USER_SAVED_CARDS = gql`
  query userSavedCards {
    userSavedCards {
      _id
      payment_gateway
      card_type
      last4
      exp_month
      exp_year
    }
  }
`;

export const GET_TEAM_MEMBER = gql`
  query getMember($input: GetTeamMemberInput!) {
    getMember(input: $input) {
      _id
      firstName
      lastName
      email
      url
      imageUrl
      coursesPaidFor {
        course_id
        course {
          _id
          name
          author {
            _id
            firstName
          }
        }
      }
      watchHistory {
        userId
        lessonId
        updatedAt
        lesson {
          name
          _id
          url
          course {
            _id
            name
            url
          }
        }
      }
    }
  }
`;

export const GET_ARTICLES = gql`
  query allPosts($where: PostFilter, $sort: [PostSorting!], $limit: Int, $offset: Int) {
    allPost(where: $where, sort: $sort, limit: $limit, offset: $offset) {
      _id
      title
      slug {
        current
      }
      bodyRaw
      categories {
        _id
        title
      }
      coverImage {
        asset {
          url
        }
      }
      tags
      author {
        _id
        image {
          asset {
            url
          }
        }
        slug {
          current
        }
        name
        bioRaw
      }
      _updatedAt
    }
  }
`;

export const GET_ARTICLE_BY_SLUG = gql`
  query getArticleBySlug($where: PostFilter) {
    allPost(where: $where, limit: 1) {
      _id
      title
      slug {
        current
      }
      bodyRaw
      categories {
        _id
        title
      }
      coverImage {
        asset {
          url
        }
      }
      tags
      author {
        _id
        image {
          asset {
            url
          }
        }
        slug {
          current
        }
        name
        bioRaw
      }
      _updatedAt
    }
  }
`;

export const BLOG_COMMENTS = gql`
  query blogComments($input: BlogCommentQueryInput!) {
    blogComments(input: $input) {
      _id
      fullname
      email
      articleId
      commentId
      body
      _createdAt
      _updatedAt
      userId
      user {
        _id
        fullname
        email
        imageUrl
      }
    }
  }
`;
